import { ILeadTransactionsConfig, IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadTransactionsConfig implements ILeadTransactionsConfig {

    public maskSameDealerSales: boolean = false;

    public excludeFromLeadDetailsTemplate: string[] = ['customerComments'];

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Lead Date',
            columnDef: 'created',
            metricFormatKey: constants.formatKeys.dateTime,
        },
        {
            show: true,
            header: 'Dealer Code',
            columnDef: 'dealerCode',
            metricFormatKey: constants.formatKeys.entityDisplayName,
        },
        {
            show: true,
            header: 'Dealer Name',
            columnDef: 'dealerName',
            metricFormatKey: constants.formatKeys.entityDisplayName,
        },
        {
            show: true,
            header: 'Lead Source',
            columnDef: 'sourceName',
            metricFormatKey: constants.formatKeys.entityDisplayName,
        },
        {
            show: true,
            header: 'Customer Name',
            columnDef: 'customerName',
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        // Email
        {
          show: true,
          header: 'Email',
          columnDef: 'emailAddress',
          metricFormatKey: constants.formatKeys.entityDisplayName,
          clickable: false
        },
        // Home Phone
        {
          show: true,
          header: 'Home Phone',
          columnDef: 'homePhone',
          metricFormatKey: constants.formatKeys.entityDisplayName,
          clickable: false
        },
        // Work Phone
        {
          show: true,
          header: 'Work Phone',
          columnDef: 'workPhone',
          metricFormatKey: constants.formatKeys.entityDisplayName,
          clickable: false
        },
        // Mobile Phone
        {
          show: true,
          header: 'Mobile Phone',
          columnDef: 'mobilePhone',
          metricFormatKey: constants.formatKeys.entityDisplayName,
          clickable: false
        },
        {
            show: true,
            header: 'City',
            columnDef: 'city',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
        },
        {
            show: true,
            header: 'State',
            columnDef: 'state',
            metricFormatKey: constants.formatKeys.entityDisplayName,
        },
        {
            show: true,
            header: 'Model',
            columnDef: 'vehicleModel',
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Live Date',
            columnDef: 'liveDate',
            metricFormatKey: constants.formatKeys.dateTime,
            clickable: false
        },
        {
            show: true,
            header: 'Responded',
            columnDef: 'responded',
            metricFormatKey: constants.formatKeys.dateTime,
        },
        {
            show: true,
            header: 'Time to Respond',
            columnDef: 'timeToRespond',
            metricFormatKey: constants.formatKeys.hoursTimeStringFromMinutes
        },
        {
            show: true,
            header: 'Sale Model',
            columnDef: 'saleModel',
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Sale Dealer',
            columnDef: 'saleDealerCode',
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Sale Dealer Name',
            columnDef: 'saleDealerName',
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Sale Date',
            columnDef: 'saleDate',
            metricFormatKey: constants.formatKeys.date
        },
        {
            show: true,
            header: 'Competing Dealers',
            columnDef: 'competingDealers',
            metricFormatKey: constants.formatKeys.localeString
        },
        {
            show: true,
            header: 'Days to Close',
            columnDef: 'timeToClose',
            metricFormatKey: constants.formatKeys.localeStringOrNa
        },
    ];

}
