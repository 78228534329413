import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Enums, IEnums } from '../_shared/enums/enums';
import * as Models from '../_shared/models/models-index';
import { IAuthenticationInfo, User, IOrgLookup, IDealerLookup, IRouteConfig, IRouteDetails } from '../_shared/models/models-index';
import { FilterService, LocalCacheService, ConfigurationService, LocaleService } from '../_shared/services/services-index';
import * as Modals from '../_shared/modals/modals-index';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { States } from '../_store/app.reducer';
import { selectLoggedInUser, isLoggedIn, selectOrgLookups } from '../_store/app.selectors';
import { map, tap, take } from 'rxjs/operators';
import { AppSelectors } from '../_store/selector-types';
import { LayoutHelperService } from './layoutHelper.service';
import { rotate90, rotate180 } from '../_shared/utilities/app-animations';
import { FilterBarService } from '../_shared/filter/filter-bar.service';
import { environment } from '../../environments/environment';
import { UsersService } from '../_shared/services/api/api-index';

@Component({
  selector: 'layout-sidebar',
  templateUrl: './sidebar.component.html',
  animations: [rotate90, rotate180],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSidebarComponent implements OnInit, OnDestroy {
  @ViewChildren('currentMenuTrigger') menuTriggers: QueryList<MatMenuTrigger>;
  // @ViewChild('leadMenuTrigger') leadMenuTrigger: MatMenuTrigger;
  // @ViewChild('managementMenuTrigger') managementMenuTrigger: MatMenuTrigger;
  // @ViewChild('websiteMenuTrigger') websiteMenuTrigger: MatMenuTrigger;
  // @ViewChild('salesMenuTrigger') salesMenuTrigger: MatMenuTrigger;
  // @ViewChild('dealerResourceMenuTrigger') dealerResourceMenuTrigger: MatMenuTrigger;
  locale: string;
  subscriptions: Subscription[] = [];
  orgLookups: IOrgLookup[];
  orgLookups$ = this.store$.select(AppSelectors.selectOrgLookups);
  dealerLookups$ = this.store$.select(AppSelectors.selectDealerLookups);
  routeConfigs: Models.IRouteConfig[] = undefined;
  // Auth/User info
  authInfo: IAuthenticationInfo;
  authInfo$ = this.store$.pipe(select(selectLoggedInUser));
  isAuthenticated$ = this.store$.pipe(select(isLoggedIn));
  isRegionManager$ = this.authInfo$.pipe(
    map(authInfo => this.configService.role.isOrg2Role(authInfo.role)));

  constructor(
    private userService: UsersService,
    public configService: ConfigurationService,
    public dialog: MatDialog,
    private localeService: LocaleService,
    private filterBarService: FilterBarService,
    public layoutHelper: LayoutHelperService,
    private router: Router,
    private store$: Store<States.AppState>) {
    this.routeConfigs = this.configService.routing.routingConfig.routes.filter(r => r.displayInNav === true) || [];
  }

  enums: IEnums = Enums;

  ngOnInit() {

    // fix route configs to support child displayInNav by removing 
    this.routeConfigs.forEach(parent => parent.children = parent.children.filter(child => child.displayInNav == null || !!child.displayInNav));

    this.subscriptions.push(
      this.authInfo$.subscribe(authInfo => this.authInfo = authInfo),

      this.localeService.locale$.pipe(tap(loc => this.locale = loc)).subscribe(),

      this.store$.select(selectOrgLookups).pipe(
        tap(orgLookups => this.orgLookups = orgLookups)).subscribe(),

      // whenever user clicks on navigation links we should close sidebar
      // and all popups
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.closeMobileSidebar();
          this.closeAllPopups();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe);
  }

  showNav = (routeConfig: Models.IRouteConfig) => {
    const show = this.layoutHelper.showNavItem(this.authInfo, routeConfig);

    return show;
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  openMenu(menuTrigger: MatMenuTrigger) {
    // Voodoo time:
    const selectedMenuId = menuTrigger['_element']['nativeElement']['id'];
    const showChildren = !!this.routeConfigs.find(r => r.id === selectedMenuId).children;

    this.closeAllPopups();
    if (!!showChildren && this.isMobileSidebarOpened()) {
      menuTrigger.openMenu();
    }
  }

  openExternalTool(routeConfig: IRouteDetails, openInNewTab: boolean) {
    if (openInNewTab == true) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`${routeConfig.parent}/${routeConfig.route}`], { queryParams: { app: routeConfig.routeData.app } }));
      window.open(url, '_blank');
    }
    else {
      let url = '';
      const auth = this.authInfo;
  
      if (routeConfig.route === 'retailerTools') {
        url += environment.retailerToolsBaseUri;
        if (!!environment.retailerToolsIncludeAuth) {
          url += `/auth?userName=${auth.clientUserName}&firstName=${auth.firstName}&lastName=${auth.lastName}&role=${auth.role}&roleEntity=${auth.roleEntity}&handoffToken=a3iojd7adiou`;
        }
        window.open(url);
      }
      else if (routeConfig.route === 'mysteryShop') {
        url = this.layoutHelper.generateMysteryShopUrl(this.authInfo);
        window.open(url);
      }
    }
  }

  closeAllPopups() {
    this.menuTriggers.forEach(m => m.closeMenu());
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(targetElement: HTMLElement) {
    // close all children sidebar windows whenever we click anywhere
    this.closeAllPopups();
  }

  closeMobileSidebar() {
    document.querySelector('body').classList.remove('sidebar-mobile-show');
  }

  isMobileSidebarOpened(): boolean {
    // if is in desktop mode just return true to pass check above
    if (window.innerWidth >= 991) {
      return true;
    }

    return document.querySelector('body').classList.contains('sidebar-mobile-show')
  }
}
