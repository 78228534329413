export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Kia America -',
  hostName: 'staging.analytics.kiadigitalleads.com',
  environmentName: 'staging',
  appName: 'Kia Digital Analytics',
  ssoPassword: 'ShiftDigital#1',
  applicationInsights: {
    instrumentationKey: '7f19fd87-1253-4cdb-bd15-e6fea32f7f31'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Pacific Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#384752', '#52616C', '#05141F', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://kia-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://kia-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://kiaus-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: 'https://kiaus-retailertools-web-staging.azurewebsites.net',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/kia-us/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['DigitalAdvertisingOverview']
};
