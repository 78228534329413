import { KpiSheetColumn } from '../../../../_shared/components/kpi-sheet/kpi-sheet-models';
import {
    IReputationManagementOverviewConfig,
    IReputationManagementOverviewBillboardConfig,
    IDualAxisLineGraphConfig,
    ICardHeaderConfig,
    IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class ReputationManagementOverviewConfig implements IReputationManagementOverviewConfig {
    public reportTitle = 'Reputation Management';
    public useDefaultComparisonColumns = true;
    public billboards: IReputationManagementOverviewBillboardConfig[] = [
        {
            title: 'Average Rating',
            subtitle: '',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'averageRating',
            metricMOMPropertyName: 'averageRatingMOM',
            metricYOYPropertyName: 'averageRatingYOY',
            metricPreviousMonthPropertyName: 'averageRatingPreviousMonth',
            metricPreviousMTDPropertyName: 'averageRatingPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: 'averageRatingPreviousYear',
            showTrendArrow: true,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            metricFormatKey: constants.formatKeys.roundToTenth,
            footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
            trendArrowDrivingPropertyName: 'averageRatingMOM',
            helpTextKey: constants.helpTextKeys.reputationManagementOverviewAverageRatingBillboardHelp,
            helpTextTitle: 'Average Rating'
        },
        {
            title: 'Total Positive Reviews',
            subtitle: '',
            iconClass: 'fa-comments fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'totalPositive',
            metricMOMPropertyName: 'totalPositiveMOM',
            metricYOYPropertyName: 'totalPositiveYOY',
            metricPreviousMonthPropertyName: 'totalPositivePreviousMonth',
            metricPreviousMTDPropertyName: 'totalPositivePreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: 'totalPositivePreviousYear',
            trendArrowDrivingPropertyName: 'totalPositiveMOM',
            showTrendArrow: true,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: constants.helpTextKeys.reputationManagementOverviewTotalPositiveReviewsBillboardHelp,
            helpTextTitle: 'Total Positive Reviews'
        },
        {
            title: 'Total Negative Reviews',
            subtitle: '',
            iconClass: 'fa-comments fa-primary',
            reverseMetric: true,
            metricCurrentPropertyName: 'totalNegative',
            metricMOMPropertyName: 'totalNegativeMOM',
            metricYOYPropertyName: 'totalNegativeYOY',
            metricPreviousMonthPropertyName: 'totalNegativePreviousMonth',
            metricPreviousMTDPropertyName: 'totalNegativePreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: 'totalNegativePreviousYear',
            trendArrowDrivingPropertyName: 'totalNegativeMOM',
            showTrendArrow: true,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: constants.helpTextKeys.reputationManagementOverviewTotalNegativeReviewsBillboardHelp,
            helpTextTitle: 'Total Negative Reviews'
        },
        {
            title: 'Total Dealer Responses',
            subtitle: '',
            iconClass: 'fa-comments fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'totalDealerResponses',
            metricMOMPropertyName: 'totalDealerResponsesMOM',
            metricYOYPropertyName: 'totalDealerResponsesYOY',
            metricPreviousMonthPropertyName: 'totalDealerResponsesPreviousMonth',
            metricPreviousMTDPropertyName: 'totalDealerResponsesPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: 'totalDealerResponsesPreviousYear',
            trendArrowDrivingPropertyName: 'totalDealerResponsesMOM',
            showTrendArrow: true,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: constants.helpTextKeys.reputationManagementOverviewTotalDealerResponsesBillboardHelp,
            helpTextTitle: 'Total Dealer Responses'
        }
    ];

    public kpiSheetOptions = {
        overview: [{
            name: 'providerId',
            label: 'Provider',
            isHeader: true
        }, {
            name: 'averageRating',
            label: 'Average Rating',
            format: constants.formatKeys.roundToThousandths
        }, {
            name: 'totalPositive',
            label: 'Total Positive Reviews',
            format: constants.formatKeys.localeString
        }, {
            name: 'totalNegative',
            label: 'Total Negative Reviews',
            reverseMetric: true,
            format: constants.formatKeys.localeString
        }, {
            name: 'totalReviews',
            label: 'Total Reviews',
            format: constants.formatKeys.localeString
        }, {
            name: 'totalDealerResponses',
            label: 'Total Dealer Responses',
            format: constants.formatKeys.localeString
        }]
    };

    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.chatSummaryHelp,
        helpTextTitle: 'Reputation Management Summary',
        exportName: 'Reputation Management - Summary Data',
        metricDisplayModes: ['MOM', 'YOY']
    };

    public trendGraphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.reputationManagementMetrics.averageRating.metricId,
                displayName: Enums.reputationManagementMetrics.averageRating.name,
                propertyName: Enums.reputationManagementMetrics.averageRating.nameKey,
                metricFormatKey: constants.formatKeys.roundToThousandths
            },
            {
                id: Enums.reputationManagementMetrics.totalPositiveReviews.metricId,
                displayName: Enums.reputationManagementMetrics.totalPositiveReviews.name,
                propertyName: Enums.reputationManagementMetrics.totalPositiveReviews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.reputationManagementMetrics.totalNegativeReviews.metricId,
                displayName: Enums.reputationManagementMetrics.totalNegativeReviews.name,
                propertyName: Enums.reputationManagementMetrics.totalNegativeReviews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.reputationManagementMetrics.totalReviews.metricId,
                displayName: Enums.reputationManagementMetrics.totalReviews.name,
                propertyName: Enums.reputationManagementMetrics.totalReviews.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.reputationManagementMetrics.totalDealerResponses.metricId,
                displayName: Enums.reputationManagementMetrics.totalDealerResponses.name,
                propertyName: Enums.reputationManagementMetrics.totalDealerResponses.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
        ],

        defaultMetricOneId: Enums.reputationManagementMetrics.totalPositiveReviews.metricId,
        defaultMetricTwoId: Enums.reputationManagementMetrics.totalNegativeReviews.metricId,
        metricOneColorHexCode: '#05141F',
        metricTwoColorHexCode: '#9EA1A2'
    };
    public dealerTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            hasComparisonMetrics: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            hasComparisonMetrics: false,
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Average Rating',
            columnDef: 'averageRating', hasComparisonMetrics: true, metricFormatKey: constants.formatKeys.roundToThousandths },
        {
            show: true,
            header: 'Total Positive Reviews',
            columnDef: 'totalPositive', hasComparisonMetrics: true, metricFormatKey: constants.formatKeys.localeString },
        {
            show: true,
            header: 'Total Negative Reviews',
            columnDef: 'totalNegative', hasComparisonMetrics: true, metricFormatKey: constants.formatKeys.localeString },
        {
            show: true,
            header: 'Total Reviews',
            columnDef: 'totalReviews', hasComparisonMetrics: true, metricFormatKey: constants.formatKeys.localeString },
        {
            show: true,
            header: 'Total Dealer Responses',
            columnDef: 'totalDealerResponses', hasComparisonMetrics: true, metricFormatKey: constants.formatKeys.localeString },
    ];
}
