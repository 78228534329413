import { IDefaultTableColumnConfig, ILeadsSummaryConfig, IDualAxisLineGraphConfig, IPropertyOverride, INameValuePair } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsSummaryConfig implements ILeadsSummaryConfig {
    public reportTitle = 'Leads Summary';
    public metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' },
        { name: 'Trend', value: 'Trend' }
    ];
    public useDefaultComparisonColumns = true;

    public dealerLevelMetricOverrides: IPropertyOverride[] = [
        {
            baseProperty: 'bestMatchSales',
            overrideProperty: 'sameDealerBestMatchSales'
        },
        {
          baseProperty: 'bestMatchCloseRate',
          overrideProperty: 'sameDealerBestMatchCloseRate'
        },
        {
          baseProperty: 'bestMatchCloseRate30',
          overrideProperty: 'sameDealerBestMatchCloseRate30'
        },
        {
          baseProperty: 'bestMatchCloseRate60',
          overrideProperty: 'sameDealerBestMatchCloseRate60'
        },
        {
          baseProperty: 'bestMatchCloseRate90',
          overrideProperty: 'sameDealerBestMatchCloseRate90'
        },
        {
          baseProperty: 'bestMatchCloseRate120',
          overrideProperty: 'sameDealerBestMatchCloseRate120'
        },
    ];

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: 'Total Sales Leads',
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.uniqueLeads.metricId,
                displayName: 'Total Unique Leads',
                propertyName: Enums.leadMetrics.uniqueLeads.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.responseRate.metricId,
                displayName: Enums.leadMetrics.responseRate.name,
                propertyName: Enums.leadMetrics.responseRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate15.metricId,
                displayName: 'Response Rate < 15 Min',
                propertyName: Enums.leadMetrics.responseRate15.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate30.metricId,
                displayName: 'Response Rate < 30 Min',
                propertyName: Enums.leadMetrics.responseRate30.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.responseRate60.metricId,
                displayName: 'Response Rate < 60 Min',
                propertyName: Enums.leadMetrics.responseRate60.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.averageResponseTime48Hour.metricId,
                displayName: 'Avg. Response Time (48 Hrs)',
                propertyName: Enums.leadMetrics.averageResponseTime48Hour.nameKey,
                metricFormatKey: constants.formatKeys.minutesTimeString
            },
            {
                id: Enums.leadMetrics.averageResponseTime.metricId,
                displayName: 'Overall Average Response TIme',
                propertyName: Enums.leadMetrics.averageResponseTime.nameKey,
                metricFormatKey: constants.formatKeys.minutesTimeString
            },
            {
                id: Enums.leadMetrics.appointmentRate.metricId,
                displayName: 'Appointment Rate',
                propertyName: Enums.leadMetrics.appointmentRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.bestMatchSales.metricId,
                displayName: 'Lead Sales',
                propertyName: Enums.leadMetrics.bestMatchSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.lostDealerSales.metricId,
                displayName: 'Lost Sales',
                propertyName: 'lostDealerSales',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.bestMatchCloseRate.metricId,
                displayName: 'Close Rate',
                propertyName: Enums.leadMetrics.bestMatchCloseRate.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.bestMatchCloseRate30.metricId,
                displayName: '30 Day Close Rate',
                propertyName: Enums.leadMetrics.bestMatchCloseRate30.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.bestMatchCloseRate60.metricId,
                displayName: '60 Day Close Rate',
                propertyName: Enums.leadMetrics.bestMatchCloseRate60.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.bestMatchCloseRate90.metricId,
                displayName: '90 Day Close Rate',
                propertyName: Enums.leadMetrics.bestMatchCloseRate90.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.bestMatchCloseRate120.metricId,
                displayName: '120 Day Close Rate',
                propertyName: Enums.leadMetrics.bestMatchCloseRate120.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
        defaultMetricTwoId: Enums.leadMetrics.bestMatchCloseRate.metricId,
        metricOneColorHexCode: '#05141F',
        metricTwoColorHexCode: '#9EA1A2'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            hasComparisonMetrics: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            hasComparisonMetrics: false,
            metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
        },

        //Total Leads
        {
            show: true,
            header: 'Total Sales Leads',
            columnDef: 'leadVolume',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Unique Leads
        {
            show: true,
            header: 'Total Unique Leads',
            columnDef: 'uniqueLeads',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Response Rate
        {
            show: true,
            header: 'Response Rate',
            columnDef: 'responseRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Response Rate < 15
        {
            show: true,
            header: 'Response Rate < 15 Min',
            columnDef: 'responseRate15',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Response Rate < 30
        {
            show: true,
            header: 'Response Rate < 30 Min',
            columnDef: 'responseRate30',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Response Rate < 60
        {
            show: true,
            header: 'Response Rate < 1 Hr',
            columnDef: 'responseRate60',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },


        //Average Response Time 48 Hrs
        {
            show: true,
            header: 'Average Response Time (48 Hrs)',
            columnDef: 'averageResponseTime48Hour',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.minutesTimeString,
            printFormatKey: constants.formatKeys.roundToInteger,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Overall Average Response
        {
            show: true,
            header: 'Overall Average Response Time',
            columnDef: 'averageResponseTime',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.minutesTimeString,
            printFormatKey: constants.formatKeys.roundToInteger,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Appointment Rate
        {
            show: true,
            header: 'Appointment Rate',
            columnDef: 'appointmentRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Lead Sales
        {
            show: true,
            header: 'Lead Sales',
            columnDef: 'bestMatchSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Lost Sales
        {
            show: true,
            header: 'Lost Sales',
            columnDef: 'lostDealerSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.localeString,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //Close Rate
        {
            show: true,
            header: 'Close Rate',
            columnDef: 'bestMatchCloseRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        //30 DayClose Rate
        {
            show: true,
            header: '30 Day Close Rate',
            columnDef: 'bestMatchCloseRate30',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
        },

        //60 DayClose Rate
        {
            show: true,
            header: '60 Day Close Rate',
            columnDef: 'bestMatchCloseRate60',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
        },

        //90 DayClose Rate
        {
            show: true,
            header: '90 Day Close Rate',
            columnDef: 'bestMatchCloseRate90',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
        },

        //120 DayClose Rate
        {
            show: true,
            header: '120 Day Close Rate',
            columnDef: 'bestMatchCloseRate120',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
        },

    ];
    public trendTableMetrics: INameValuePair[] = [
      {name: 'Total Sales Leads', value: 'leadVolume'},
      {name: 'Total Unique Leads', value: 'uniqueLeads'},
      {name: 'Response Rate', value: 'responseRate'},
      {name: 'Response Rate < 15 Min', value: 'responseRate15'},
      {name: 'Response Rate < 30 Min', value: 'responseRate30'},
      {name: 'Response Rate < 1 Hr', value: 'responseRate60'},
      {name: 'Avg. Response Time (48 Hr)', value: 'averageResponseTime48Hour'},
      {name: 'Overall Avg. Response Time', value: 'averageResponseTime'},
      {name: 'Appointment Rate', value: 'appointmentRate'},
      {name: 'Lead Sales', value: 'bestMatchSales'},
      {name: 'Lost Sales', value: 'lostDealerSales'},
      {name: 'Close Rate', value: 'bestMatchCloseRate'},
      {name: '30 Day Close Rate', value: 'bestMatchCloseRate30'},
      {name: '60 Day Close Rate', value: 'bestMatchCloseRate60'},
      {name: '90 Day Close Rate', value: 'bestMatchCloseRate90'},
      {name: '120 Day Close Rate', value: 'bestMatchCloseRate120'},
    ];
}
