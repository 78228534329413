import { IDefaultTableColumnConfig, ILeadsVehicleConfig, IMultiLevelDualAxisLineGraphConfig, IPropertyOverride } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsVehicleConfig implements ILeadsVehicleConfig {
    metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
    ];

    public dealerLevelMetricOverrides: IPropertyOverride[] = [
        {
          baseProperty: 'bestMatchSales',
          overrideProperty: 'sameDealerBestMatchSales'
        },
        {
          baseProperty: 'bestMatchCloseRate',
          overrideProperty: 'sameDealerBestMatchCloseRate'
        },
        {
          baseProperty: 'percentOfBestMatchSales',
          overrideProperty: 'sameDealerPercentOfBestMatchSales'
        },
    ];

    public useDefaultComparisonColumns = true;
    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
            {
                id: Enums.leadMetrics.leadVolume.metricId,
                displayName: Enums.leadMetrics.leadVolume.name,
                propertyName: Enums.leadMetrics.leadVolume.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.uniqueLeads.metricId,
                displayName: 'Unique Leads',
                propertyName: 'uniqueLeads',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.leadSales.metricId,
                displayName: Enums.leadMetrics.leadSales.name,
                propertyName: 'bestMatchSales',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.closeRate.metricId,
                displayName: Enums.leadMetrics.closeRate.name,
                propertyName: 'bestMatchCloseRate',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.gainedSales.metricId,
                displayName: Enums.leadMetrics.gainedSales.name,
                propertyName: 'gainedSales',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.lostSales.metricId,
                displayName: Enums.leadMetrics.lostSales.name,
                propertyName: 'lostVehicleSales',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.leadMetrics.percentOfLeads.metricId,
                displayName: Enums.leadMetrics.percentOfLeads.name,
                propertyName: 'percentOfLeadVolume',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.leadMetrics.percentOfSales.metricId,
                displayName: Enums.leadMetrics.percentOfSales.name,
                propertyName: 'percentOfBestMatchSales',
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
        ],
        childMetrics: [], // this needs to be data driven

        defaultChildrenIds: [ 'All Models', 'All Models' ],
        defaultParentIds: [ Enums.leadMetrics.leadVolume.metricId, Enums.leadMetrics.leadSales.metricId ],
        metricOneColorHexCode: '#05141F',
        metricTwoColorHexCode: '#9EA1A2'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Vehicle Model',
            columnDef: 'entity',
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },

        // Lead Sales
        {
            show: true,
            header: 'Lead Volume',
            columnDef: 'leadVolume',
            hasComparisonMetrics: true,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            metricFormatKey: constants.formatKeys.localeString
        },

        // Unique Leads
        {
            show: true,
            header: 'Unique Leads',
            columnDef: 'uniqueLeads',
            hasComparisonMetrics: true,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            metricFormatKey: constants.formatKeys.localeString
        },

        // Lead Sales
        {
            show: true,
            header: 'Lead Sales',
            columnDef: 'bestMatchSales',
            hasComparisonMetrics: true,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            metricFormatKey: constants.formatKeys.localeString
        },

        // Close Rate
        {
            show: true,
            header: 'Close Rate',
            columnDef: 'bestMatchCloseRate',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // Gained Sales
        {
            show: true,
            header: 'Gained Sales',
            columnDef: 'gainedSales',
            hasComparisonMetrics: true,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            metricFormatKey: constants.formatKeys.localeString
        },

        // Lost Sales
        {
            show: true,
            header: 'Lost Sales',
            columnDef: 'lostVehicleSales',
            hasComparisonMetrics: true,
            comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
            metricFormatKey: constants.formatKeys.localeString
        },

        // % Of Leads
        {
            show: true,
            header: '% of Leads',
            columnDef: 'percentOfLeadVolume',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },

        // % Of Sales
        {
            show: true,
            header: '% of Sales',
            columnDef: 'percentOfBestMatchSales',
            hasComparisonMetrics: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
    ];
}
