import {
  IDigAdOverviewBillboardConfig,
  IDigAdOverviewConfig,
  IDefaultTableColumnConfig,
  IDigAdOverviewKpiConfig,
  ICardHeaderConfig,
  IDigAdOverviewChannelCardConfig,
  IDigAdOverviewTrafficTypeCardConfig
} from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
export class DigAdOverviewConfig implements IDigAdOverviewConfig {

  public reportTitle: string = 'Digital Advertising Overview';
  public chartSeriesColors = ['#05141F', '#9EA1A2', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
  public channelColors: ['#c51230', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'];
    
  public providerPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Provider Performance',
    iconClass: 'fa-tasks',
    helpTextKey: constants.helpTextKeys.digAdOverviewProviderPerformanceHelp,
    helpTextTitle: 'Provider Performance',
  };
  public dealerPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Dealer Performance',
    iconClass: 'fa-suitcase',
    helpTextKey: constants.helpTextKeys.digAdOverviewDealerPerformanceHelp,
    helpTextTitle: 'Dealer Performance',
  };
  public trafficTrendCardHeader: ICardHeaderConfig = {
    title: 'Traffic Trend',
    iconClass: 'fa-bar-chart',
    helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTrendHelp,
    helpTextTitle: 'Traffic Trend',
  };
  public channelPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Channels',
    iconClass: '',
    helpTextKey: constants.helpTextKeys.digAdOverviewChannelPerformanceHelp,
    helpTextTitle: 'Channels',
  };
  public trafficTypeCardHeader: ICardHeaderConfig = {
    title: 'Organic vs Paid',
    iconClass: 'fa-adjust',
    helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTypeHelp,
    helpTextTitle: 'Traffic Type',
  };

  public channelCardConfig: IDigAdOverviewChannelCardConfig = {
    channelColors: ['#c51230', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'],
    dimensionPropertyName: 'displayName',
    primaryMetricPropertyName: 'providerTotalVisits',
    secondaryMetricPropertyName: 'visitsWithEngagements'
  };

  public trafficTypeCardConfig: IDigAdOverviewTrafficTypeCardConfig = {
    headerConfig: {
      title: 'Organic vs Paid',
      iconClass: 'fa-adjust',
      helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTypeHelp,
      helpTextTitle: 'Traffic Type',
    },
    chartConfig: {
      colors: ['#05141F', '#9EA1A2'],
      paidPropertyName: 'providerTotalVisits',
      organicPropertyName: 'organicWebsiteVisits'
    }
  }

  public billboards: IDigAdOverviewBillboardConfig[] = [
    {
      title: 'Impressions',
      subtitle: null,
      // subtitleOne: 'impressionsPreviousMTD',
      // subtitleTwo: 'impressionsPreviousMonth',
      subtitleOne: null,
      subtitleTwo: null,
      iconClass: 'fa-mouse-pointer fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'impressions',
      metricMOMPropertyName: 'impressionsMOM',
      metricYOYPropertyName: 'impressionsYOY',
      metricPreviousMonthPropertyName: 'impressionsPreviousMonth',
      metricPreviousMTDPropertyName: 'impressionsMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'impressionsMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'DigAdOverviewImpressionsBillboardHelp',
      helpTextTitle: 'Impressions',
      showTrendArrow: true
    },
    {
      title: 'Click Though Rate',
      subtitle: null,
      subtitleOne: 'clickThroughRatePreviousMTD',
      subtitleTwo: 'clickThroughRatePreviousMonth',
      iconClass: 'fa-mouse-pointer fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'clickThroughRate',
      metricMOMPropertyName: 'clickThroughRateMOM',
      metricYOYPropertyName: 'clickThroughRateYOY',
      metricPreviousMonthPropertyName: 'clickThroughRatePreviousMonth',
      metricPreviousMTDPropertyName: 'clickThroughRateMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'clickThroughRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigAdOverviewClickThroughRateBillboardHelp',
      helpTextTitle: 'Click Through Rate',
      showTrendArrow: true
    },
    {
      title: 'Total Visits',
      subtitle: null,
      subtitleOne: 'providerTotalVisitsPreviousMTD',
      subtitleTwo: 'providerTotalVisitsPreviousMonth',
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'providerTotalVisits',
      metricMOMPropertyName: 'providerTotalVisitsMOM',
      metricYOYPropertyName: 'providerTotalVisitsYOY',
      metricPreviousMonthPropertyName: 'providerTotalVisitsPreviousMonth',
      metricPreviousMTDPropertyName: 'providerTotalVisitsMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'providerTotalVisitsMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'DigAdOverviewTotalVisitsBillboardHelp',
      helpTextTitle: 'Total Visits',
      showTrendArrow: true
    },
    {
      title: 'Conversion Rate',
      subtitle: null,
      subtitleOne: 'conversionRatePreviousMTD',
      subtitleTwo: 'conversionRatePreviousMonth',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'conversionRate',
      metricMOMPropertyName: 'conversionRateMOM',
      metricYOYPropertyName: 'conversionRateYOY',
      metricPreviousMonthPropertyName: 'conversionRatePreviousMonth',
      metricPreviousMTDPropertyName: 'conversionRateMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'conversionRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigAdOverviewConversionRateBillboardHelp',
      helpTextTitle: 'Conversion Rate',
      showTrendArrow: true
    },
  ];

  public kpiSheetOptions = {
    overview: [{
      name: 'displayName',
      label: '',
      isHeader: true
    }, {
      name: 'impressions',
      label: 'Impressions',
      format: constants.formatKeys.abbreviatedLocaleString
    }, {
      name: 'clickThroughRate',
      label: 'Click Through Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'providerTotalVisits',
      label: 'Total Visits',
      format: constants.formatKeys.abbreviatedLocaleString
    }, {
      name: 'engagementRate',
      label: 'Engagement Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'conversionRate',
      label: 'Conversion Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'salesServiceOtherBounces',
      label: 'Sales | Service | Other | Bounces',
    }]
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      metricFormatKey: constants.formatKeys.entityDisplayName,
    },

    // Engagement Rate
    {
      show: true,
      header: 'Engagement Rate',
      columnDef: 'engagementRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Action Rate
    {
      show: true,
      header: 'Action Rate',
      columnDef: 'actionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Impressions
    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Clicks
    {
      show: true,
      header: 'Clicks',
      columnDef: 'clicks',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Click Through Rate
    {
      show: true,
      header: 'Click Through Rate',
      columnDef: 'clickThroughRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    {
      show: true,
      header: 'Cost per Click',
      columnDef: 'actualCostPerClick',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },

    {
      show: true,
      header: 'Cost per Thousand (CPM)',
      columnDef: 'actualCostPerThousandImpressions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },

    // Total Visits
    {
      show: true,
      header: 'Total Visits',
      columnDef: 'providerTotalVisits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Conversion Rate
    {
      show: true,
      header: 'Conversion Rate',
      columnDef: 'conversionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    {
      show: true,
      header: 'Cost per Acquisition',
      columnDef: 'actualCostPerAcquisition',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.currency
    },

    // Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'taggingBounceRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

  ];
  public dealerBenchmarkLookbackColumns?: IDefaultTableColumnConfig[] = [];
  public kpiCardConfig: IDigAdOverviewKpiConfig = {
    chartTitle: 'Total Visits vs. Engaged Visits',
    preCutoffChartTitle: 'Total Visits vs. Unique Visits',
    xAxisPropertyName: 'date',
    metrics: {
      'MetricOne': {
          yAxisTitle: 'Visits',
          displayName: 'Total Visits',
          pointLabelFormat: null,
          propertyName: 'providerTotalVisits',
          metricFormatKey: null,
          formatter: null
      },
      'MetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Engaged Visits',
          pointLabelFormat: null,
          propertyName: 'visitsWithEngagements',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Unique Visits',
          pointLabelFormat: null,
          propertyName: 'uniqueVisits',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricOne': {
        yAxisTitle: 'Visits',
        displayName: 'Total Visits',
        pointLabelFormat: null,
        propertyName: 'totalVisits',
        metricFormatKey: null,
        formatter: null
      }
    }
  };
}
