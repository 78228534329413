import { IDefaultTableColumnConfig, ISourceSummaryConfig, IDualAxisLineGraphConfig, IPropertyOverride, INameValuePair } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class SourceSummaryConfig implements ISourceSummaryConfig {

  public reportTitle = 'Source Performance';
  metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];


  public dealerLevelMetricOverrides: IPropertyOverride[] = [
    {
      baseProperty: 'householdSales',
      overrideProperty: 'sameDealerHouseholdSales'
    },
    {
      baseProperty: 'householdCloseRate',
      overrideProperty: 'sameDealerHouseholdCloseRate'
    }
  ];

  public useDefaultComparisonColumns = true;

  public graphConfig: IDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    availableMetrics: [
      {
        id: Enums.leadMetrics.leadVolume.metricId,
        displayName: 'Leads',
        propertyName: Enums.leadMetrics.leadVolume.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.leadMetrics.uniqueLeads.metricId,
        displayName: 'Unique Leads',
        propertyName: Enums.leadMetrics.uniqueLeads.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.leadMetrics.leadSales.metricId,
        displayName: 'Sales',
        propertyName: 'householdSales',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.leadMetrics.closeRate.metricId,
        displayName: 'Conversion Rate',
        propertyName: 'householdCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.averageDailyVolume.metricId,
        displayName: 'Avg. Daily Volume',
        propertyName: 'averageDailyVolume',
        metricFormatKey: constants.formatKeys.roundNumber
      },
    ],
    defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
    defaultMetricTwoId: Enums.leadMetrics.leadSales.metricId,
    metricOneColorHexCode: '#05141F',
    metricTwoColorHexCode: '#9EA1A2'
  }

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },
    {
      show: true,
      header: 'Leads',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Unique Leads',
      columnDef: 'uniqueLeads',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Sales',
      columnDef: 'householdSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Conversion Rate',
      columnDef: 'householdCloseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: true,
      header: 'Avg. Daily Volume',
      columnDef: 'averageDailyVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.roundNumber,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals
    }
  ];

  public trendTableMetrics: INameValuePair[] = [
    {name: 'Leads', value: 'leadVolume'},
    {name: 'Unique Leads', value: 'uniqueLeads'},
    {name: 'Sales', value: 'householdSales'},
    {name: 'Conversion Rate', value: 'householdCloseRate'},
    {name: 'Avg. Daily Volume', value: 'averageDailyVolume'},
  ];
}
