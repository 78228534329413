import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Observable, of, Subscription, Subject } from 'rxjs';
import * as FilterModels from './filter.model';
import { Enums } from '../enums/enums';
import * as FilterPanelTypes from '../sd-filter-panel-module/types/types';
import { SdFilterPanel } from '../sd-filter-panel-module/types/sd-filter-panel';
import { SdFilterPanelInputType, FilterPanelCloseType } from '../sd-filter-panel-module/types/constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../_store/app-state.model';
import { AppSelectors } from '../../_store/selector-types';
import { FilterBarService } from './filter-bar.service';
import { ActivatedRoute } from '@angular/router';
import { tap, switchMap, map, filter, takeUntil } from 'rxjs/operators';
import { ValueConverter } from './filter-value-converter';
import { FilterSelectors, FilterActions, ReportViewFilterSelection } from './store';
import { LocaleService } from '../locale/locale.service';
import { SharedTranslationService } from '../locale/translation/shared-translation.service';
import { ConfigurationService } from '../services/config/config.service';
import { FilterService } from '../services/services-index';
import { HyFilterPanel } from '../hy-filter-panel-module/types/filter-panel';
import { FilterPanelParams, IPanelInputData } from '../hy-filter-panel-module/types/types';
import { IFacetedFilterData, IFacetedFilterDataItem, IFacetedFilterSelection, ISelectedDateRangeInfo, ISelectedFacetInfo, ISelectedHierarchyInfo, ISelectionInfo } from '../hy-filter-panel-module/types';
import { ReportFilterItem, ReportViewFilter } from '../models/report-view.models';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarComponent implements OnInit, OnDestroy {
  filters$: Observable<FilterModels.Filter[]>;
  filterTypes$: Observable<FilterModels.FilterType[]>;
  showAddFilter$: Observable<boolean>;
  reportName: string;
  locale: string;
  orgLookupTreeNodes: FilterPanelTypes.IFilterTreeNode[] = [];
  vehicleLookupTreeNodes: FilterPanelTypes.IFilterTreeNode[] = [];
  valueConverter: ValueConverter;
  destroy$ = new Subject<boolean>();
  useNextGenFilterPanel = false;
  reportViewFilterSelections: ReportViewFilterSelection[] = [];
  reportViewFilters$: Observable<ReportViewFilterSelection[]>;

  @ViewChild('addFilterButton') addFilterButton: ElementRef;
  constructor(
    private store$: Store<AppState>,
    private configService: ConfigurationService,
    private route: ActivatedRoute,
    private filterBarService: FilterBarService,
    private filterService: FilterService,
    private filterPanelRef: SdFilterPanel,
    private nextGenFilterPanelRef: HyFilterPanel,
    private localeService: LocaleService,
    private translationService: SharedTranslationService
  ) {
    this.valueConverter = new ValueConverter();
  }

  ngOnInit() {
    this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
      takeUntil(this.destroy$),
      tap(routeData => {
        this.reportName = routeData.reportName;
      })
    ).subscribe(routeData => {
      if (routeData.useV5Filters) {
        console.log('use v5 filters');
        this.useNextGenFilterPanel = true;
      } else {
        this.getLegacyReportFilters();
        this.useNextGenFilterPanel = false;
      }
    });

    this.reportViewFilters$ = this.store$.select(FilterSelectors.selectReportViewFilterSelections({reportName: this.reportName}))
        .pipe(
            tap(selections => this.reportViewFilterSelections = selections)
        );

    this.localeService.locale$.pipe(tap(locale => this.locale = locale)).subscribe();
  }

  translateLabel = (val) => this.translationService.getLabelTranslation(val, this.locale);

  getLegacyReportFilters() {
    this.filters$ = this.getCurrentReportFilters().pipe(
      map(filters => filters.filter(f => !(f.visible === false))
      ));
    this.filterTypes$ = this.getCurrentAvailableFilterTypes();
    this.showAddFilter$ = this.filterTypes$.pipe(map(types => types && types.length > 0));

    // todo we could make these more reactive
    this.filterBarService.getOrgLookupFilterNodes().pipe(
      takeUntil(this.destroy$),
      tap(nodes => this.orgLookupTreeNodes = nodes)
    ).subscribe();

    this.store$.select(AppSelectors.selectVehicleLookups).pipe(
      takeUntil(this.destroy$),
      map(vehicleLookups => this.filterBarService.getVehicleLookupFilterNodes(vehicleLookups)),
      tap(nodes => this.vehicleLookupTreeNodes = nodes))
      .subscribe();
  }

  getCurrentReportFilters() {
    return this.store$.select(AppSelectors.selectCurrentRouteData)
      .pipe(
        switchMap(routeData => {
          this.reportName = routeData.reportName;
          return this.filterBarService.getReportFilters(routeData.reportName);
        })
      );
  }

  getCurrentAvailableFilterTypes() {
    return this.store$.select(AppSelectors.selectCurrentRouteData)
      .pipe(
        switchMap(routeData => this.filterBarService.getAvailableReportFilters(routeData.reportName))
      );
  }

  ngOnDestroy(): void {
  }

  onFilterClicked($event: MouseEvent, chip: ElementRef, filter: FilterModels.Filter) {
    this.show(chip, filter.type, filter.selected);
  }

  onFilterTypeSelected(type: FilterModels.FilterType) {
    this.show(this.addFilterButton, type.name, []);
  }

  show(origin: ElementRef, type: string, selected: FilterModels.FilterValueType[] | IFacetedFilterSelection) {
      this.showClassicFilterPanel(origin, type, selected as FilterModels.FilterValueType[]);
  }

  private transformToInputData(filters: ReportViewFilter[]): IPanelInputData {
    //const items = filters.map(filter => filter.items?.map(item => this.transformItemToDataItem(item)));
    const items: IFacetedFilterDataItem[] = [];

    filters.forEach(filter => {
      items.push({
        label: filter.label ?? filter.name,
        name: filter.name,
        type: "container",
        path: filter.label ?? filter.name,
        searchable: false,
        items: filter.items?.map(item => this.transformItemToDataItem(item))
      });
    });

    const inputData = <IFacetedFilterDataItem>{
      label: 'Filter',
      items: items
    };

    return { inputData: items };
  }

  private transformItemToDataItem(item: ReportFilterItem): IFacetedFilterDataItem {
    return {
      label: item.label ?? item.name,
      name: item.name,
      type: item.type,
      filterType: item.filterType,
      path: item.path,
      value: item.value,
      valueType: item.valueType,
      searchable: item.searchable,
      items: item.items?.map(item => this.transformItemToDataItem(item))
    }
  }

  private showClassicFilterPanel(origin: ElementRef, type: string, selected: FilterModels.FilterValueType[] = []): void {

    const filterType = this.configService.filter.filterConfig.filterTypeLookup[type];

    const filterPanelConfig: FilterPanelTypes.FilterPanelConfig = {
      panelTitle: this.translateLabel(filterType.displayName),
      type: type,
      inputData: null,
    };

    const filterPanelParams: FilterPanelTypes.SdFilterPanelParams = {
      originElement: origin,
      inputDataType: SdFilterPanelInputType.list,
      config: filterPanelConfig
    };

    // cast to simple value and keep handy since it's the most used
    const simpleSelected = <FilterModels.SimpleFilterValue[]>selected;
    const simpleInputPanelData = {
      inputData: simpleSelected.map(s => ({ value: s.value, display: this.translateLabel(s.display) }))
    };

    switch (type) {
      case 'org':
        filterPanelParams.inputDataType = SdFilterPanelInputType.collection;
        filterPanelConfig.singleSelectionMode = true;

        // converted selected values to org tree nodes
        const orgCurrentFilterValues = {
          inputData: (<FilterModels.OrgFilterValue[]>selected).map(s => ({
            display: this.translateLabel(s.display),
            value: `${s.levelTypeId}-${s.value}`,
            orgFilterValue: s
          }))
        };

        filterPanelConfig.inputData = this.filterBarService.getOrgLookupFilterPanelData(this.locale);
        filterPanelConfig.currentFilterValues = orgCurrentFilterValues;

        break;
      case 'vehicle':
        filterPanelParams.inputDataType = SdFilterPanelInputType.collection;
        filterPanelConfig.singleSelectionMode = true;

        // converted selected values to org tree nodes
        const vehicleCurrentFilterValues = {
          inputData: (<FilterModels.OrgFilterValue[]>selected).map(s => ({
            display: s.display,
            value: s.value,
            orgFilterValue: s
          }))
        };

        filterPanelConfig.inputData = this.filterBarService.getVehicleLookupFilterPanelData();
        filterPanelConfig.currentFilterValues = vehicleCurrentFilterValues;
        break;
      case 'channel':
        const channelFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getChannelFilterPanelData();
        filterPanelConfig.currentFilterValues = channelFilterData;
        break;
      case 'digad-channel':
        const digadChannelFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('DigAd-Channel', this.locale);
        filterPanelConfig.currentFilterValues = digadChannelFilterData;
        break;
      case 'brand':
        const brandFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getBrandFilterPanelData();
        filterPanelConfig.currentFilterValues = brandFilterData;
        break;
      case 'audience-segment':
        const audienceSegmentFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getAudienceSegmentFilterPanelData();
        filterPanelConfig.currentFilterValues = audienceSegmentFilterData;
        break;
      case 'inventory-status':
        const inventoryStatusFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('Inventory-Status', this.locale);
        filterPanelConfig.currentFilterValues = inventoryStatusFilterData;
        break;
      case 'inventory-types':
        const inventoryTypesFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('Inventory-Type', this.locale);
        filterPanelConfig.currentFilterValues = inventoryTypesFilterData;
        break;
      case 'journey-segment':
        const journeySegmentFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getJourneySegmentFilterPanelData();
        filterPanelConfig.currentFilterValues = journeySegmentFilterData;
        break;
      case 'campaign-type':
        const campaignTypeFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        }

        filterPanelConfig.inputData = this.filterBarService.getCampaignTypeFilterPanelData();
        filterPanelConfig.currentFilterValues = campaignTypeFilterData;
        break;
        case 'campaign-type-v5':
          const campaignTypeV5FilterData = {
            inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
          }

          filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('Campaign-Type', this.locale);
          filterPanelConfig.currentFilterValues = campaignTypeV5FilterData;
          break;
      case 'dealer-size':
        const dealerSizeFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        }

        filterPanelConfig.inputData = this.filterBarService.getDealerSizeFilterPanelData();
        filterPanelConfig.currentFilterValues = dealerSizeFilterData;
        break;
      case 'deal-type':
        const dealTypeSizeFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        }

        filterPanelConfig.inputData = this.filterBarService.getDealTypeFilterPanelData();
        filterPanelConfig.currentFilterValues = dealTypeSizeFilterData;
        break;
      case 'chat-provider':
        const chatProviderFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getChatProviderFilterPanelData();
        filterPanelConfig.currentFilterValues = chatProviderFilterData;
        break;
      case 'trade-in-provider':
        const tradeInProviderFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getTradeInProviderFilterPanelData();
        filterPanelConfig.currentFilterValues = tradeInProviderFilterData;
        break;
      case 'device-type':
        const deviceTypeFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: this.translateLabel(s.display) }))
        };

        filterPanelConfig.inputData = this.filterBarService.getDeviceTypeFilterPanelData(this.locale);
        filterPanelConfig.currentFilterValues = deviceTypeFilterData;
        break;
      case 'digad-provider':
        const digAdProviderFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getDigAdProviderFilterPanelData();
        filterPanelConfig.currentFilterValues = digAdProviderFilterData;
        break;
      case 'digad-provider-v5':
          const digAdProviderV5FilterData = {
            inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
          }

          filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('DigAd-Provider', this.locale);
          filterPanelConfig.currentFilterValues = digAdProviderV5FilterData;
          break;
      case 'digital-retailing-provider':
        const drProviderFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getDigitalRetailingProviderFilterPanelData();
        filterPanelConfig.currentFilterValues = drProviderFilterData;
        break;
      case 'lead-gate':
        const leadGateFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getLeadGateFilterPanelData();
        filterPanelConfig.currentFilterValues = leadGateFilterData;
        break;
      case 'lead-type':
        filterPanelConfig.inputData = this.filterBarService.getLeadTypeFilterPanelData(this.locale);
        filterPanelConfig.currentFilterValues = simpleInputPanelData;
        break;
      case 'trait':
        filterPanelConfig.inputData = this.filterBarService.getTraitFilterPanelData(this.locale);
        filterPanelConfig.currentFilterValues = simpleInputPanelData;
        break;
      case 'profit-center':
        // const profitCenterFilterData = {
        //   inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        // };
        //filterPanelConfig.singleSelectionMode = true;
        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('ProfitCenter', this.locale);
        filterPanelConfig.currentFilterValues = simpleInputPanelData;
        break;
      case 'digad-program':
        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('DigAd-Program', this.locale);
        filterPanelConfig.currentFilterValues = simpleInputPanelData;
        break;
      case 'reputation-provider':
        const reputationProviderFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getReputationProviderFilterPanelData();
        filterPanelConfig.currentFilterValues = reputationProviderFilterData;
        break;
      case 'reputation-platform':
        const reputationPlatformFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };
        filterPanelConfig.inputData = this.filterBarService.getReputationPlatformFilterPanelData();
        filterPanelConfig.currentFilterValues = reputationPlatformFilterData;
        break;
      case 'sale-type':
        const saleTypeFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: this.translateLabel(s.display) }))
        };

        filterPanelConfig.inputData = this.filterBarService.getSaleTypeFilterPanelData(this.locale);
        filterPanelConfig.currentFilterValues = saleTypeFilterData;
        break;
      case 'social-media-platform':
        const socialMediaPlatformFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getSocialMediaPlatformFilterPanelData();
        filterPanelConfig.currentFilterValues = socialMediaPlatformFilterData;
        break;
      case 'social-media-provider':
        const socialProviderFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getSocialMediaProviderFilterPanelData();
        filterPanelConfig.currentFilterValues = socialProviderFilterData;
        break;
      case 'source-type':
        // convert selected values to simple values array
        const sourceTypeFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: this.translateLabel(s.display) }))
        };

        filterPanelConfig.inputData = this.filterBarService.getSourceTypeFilterPanelData(this.locale);
        filterPanelConfig.currentFilterValues = sourceTypeFilterData;
        break;
      case 'lma':
        const lmaFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: this.translateLabel(s.display) }))
        };

        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('LMA', this.locale);
        filterPanelConfig.currentFilterValues = lmaFilterData;
        break;
      case 'lma-region':
        const lmaRegionFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: this.translateLabel(s.display) }))
        };

        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('LMA-Region', this.locale);
        filterPanelConfig.currentFilterValues = lmaRegionFilterData;
        break;
      case 'tactic':
        const tacticFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: this.translateLabel(s.display) }))
        };

        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('Tactics', this.locale);
        filterPanelConfig.currentFilterValues = tacticFilterData;
        break;
      case 'strategy':
          const strategyFilterData = {
            inputData: simpleSelected.map(s => ({ value: s.value, display: this.translateLabel(s.display) }))
          };

          filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('Strategies', this.locale);
          filterPanelConfig.currentFilterValues = strategyFilterData;
          break;
      case 'vehicle-makes':
            const vehicleMakes = {
              inputData: simpleSelected.map(s => ({ value: s.value, display: this.translateLabel(s.display) }))
            };

            filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('VehicleMakes', this.locale);
            filterPanelConfig.currentFilterValues = vehicleMakes;
            break;
      case 'vehicle-make':
        const makeInputFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getVehicleMakeFilterPanelData(this.locale);
        filterPanelConfig.currentFilterValues = makeInputFilterData;
        break;
      case 'vehicle-class':
        const classInputFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getVehicleClassFilterPanelData();
        filterPanelConfig.currentFilterValues = classInputFilterData;
        break;
      case 'vehicle-model':
        const modelInputFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getVehicleModelFilterPanelData(this.locale);
        filterPanelConfig.currentFilterValues = modelInputFilterData;
        break;
      case 'vehicle-models':
        const modelsInputFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('Vehicle-Model', this.locale);
        filterPanelConfig.currentFilterValues = modelsInputFilterData;
        break;
      case 'vehicle-series':
        const seriesInputFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getKeyValueFilterPanelData('Vehicle-Series', this.locale);
        filterPanelConfig.currentFilterValues = seriesInputFilterData;
        break;
      case 'vehicle-type':
        const vehicleTypeInputFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getVehicleTypeFilterPanelData();
        filterPanelConfig.currentFilterValues = vehicleTypeInputFilterData;
        break;
      case 'website-provider':
        const websiteProviderFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getWebsiteProviderFilterPanelData();
        filterPanelConfig.currentFilterValues = websiteProviderFilterData;
        break;
      case 'service-scheduler-provider':
        const serviceSchedulerProviderFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };

        filterPanelConfig.inputData = this.filterBarService.getServiceSchedulerProviderFilterPanelData();
        filterPanelConfig.currentFilterValues = serviceSchedulerProviderFilterData;
        break;
      case 'role':
        const roleFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };
        filterPanelConfig.inputData = this.filterBarService.getRoleFilterPanelData();
        filterPanelConfig.currentFilterValues = roleFilterData;
        break;
      case 'page':
        const pageFilterData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };
        filterPanelConfig.inputData = this.filterBarService.getFlatPageFilterPanelData();
        filterPanelConfig.currentFilterValues = pageFilterData;
        break;
      case 'product-line':
        const productLineFitlerData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };
        filterPanelConfig.inputData = this.filterBarService.getProductLineFilterPanelData();
        filterPanelConfig.currentFilterValues = productLineFitlerData;
        break;
      case 'sales-unit':
        const salesUnitFitlerData = {
          inputData: simpleSelected.map(s => ({ value: s.value, display: s.display }))
        };
        filterPanelConfig.inputData = this.filterBarService.getSalesUnitFilterPanelData();
        filterPanelConfig.currentFilterValues = salesUnitFitlerData;
        break;
    }

    // let currentValuesModel: IFlatFilterModel = {} as IFlatFilterModel;
    // if (currentFilterValues && currentFilterValues.length)
    // 	currentValuesModel.filterData = currentFilterValues;

    const ref = this.filterPanelRef.open(filterPanelParams);

    ref.afterClosed$
      .subscribe((result) => {
        switch (result.dialogData.resultType) {
          case FilterPanelCloseType.applied:
            this.updateFilter(result.dialogData.type, result.dialogData.data);
            break;
          case FilterPanelCloseType.backdropClick:
            break;
          case FilterPanelCloseType.cancel:
            break;
        }
      });
  }

  updateFilter(filterType: string, panelValues: FilterPanelTypes.FilterDataTypeValue[]) {
    switch (filterType) {
      case 'org':
        // translate for OrgFileValues
        const orgSelected = (<FilterPanelTypes.IFilterTreeNode[]>panelValues).map(this.valueConverter.convertToOrgFilterValue);
        this.filterBarService.updateFilterSelected(this.reportName, filterType, orgSelected);
        break;
      case 'vehicle':
        // translate for OrgFileValues
        const vehicleSelected = (<FilterPanelTypes.IFilterTreeNode[]>panelValues).map(this.valueConverter.convertToOrgFilterValue);
        this.filterBarService.updateFilterSelected(this.reportName, filterType, vehicleSelected);
        break;
      default:
        // translate for SimpleValue (pretty much every thing except org)
        const selected = (<FilterPanelTypes.SimpleValue[]>panelValues).map(this.valueConverter.convertToSimpleFilterValue);
        this.filterBarService.updateFilterSelected(this.reportName, filterType, selected);
    }
  }

  lockFilter(filter: FilterModels.Filter) {
    this.store$.dispatch(FilterActions.toggleFilterLock({ reportName: this.reportName, filter }));
  }

  removeFilter(filterType: string) {
    this.store$.dispatch(FilterActions.removeFilter({ reportName: this.reportName, filterType }));
  }

  resetFilters() {
    this.filterBarService.resetFilters();
  }

  getDisplayValue(filter: FilterModels.Filter, locale: string): string {
    let displayValue = '';

    // determine what the principal display value should be
    if (filter.type === 'org') {
      // if there are no items here, it means we're going national
      if (!filter.selected || filter.selected.length === 0) {
        const topLevel = 1;
        const nationalOrgDisplayName = this.configService.org.getOrgLookupTypeOrDefault(topLevel).name;
        return this.translateLabel(nationalOrgDisplayName);
      }

      const firstItem = <FilterModels.OrgFilterValue>filter.selected[0];
      const translatedFirstItem = this.translateLabel(firstItem.display);
      const displayValueFirstPart = '';

      displayValue = `${displayValueFirstPart}${translatedFirstItem}`;
    } else if (filter.type === 'vehicle') {
      if (!filter.selected || filter.selected.length === 0) {
        return this.translateLabel('All Classes');
      }
      const firstItem = <FilterModels.OrgFilterValue>filter.selected[0];
      const displayValueFirstPart = firstItem.entityType !== 'allclasses'
        ? `${Enums.vehicleEntityTypes[firstItem.entityType].displayName}: `
        : '';

      displayValue = `${displayValueFirstPart}${firstItem.display}`;
    } else {

      const filterType = this.configService.filter.filterConfig.filterTypeLookup[filter.type];
      // if we have none selected, it essentially means all
      if (!filter.selected || filter.selected.length === 0) {
        return `${this.translateLabel(this.configService.filter.filterConfig.filterTypeLookup[filter.type].displayName)}: ${this.translateLabel('All')}`;
      } else {
        const simpleFirstItem = <FilterModels.SimpleFilterValue>filter.selected[0];

        displayValue = `${this.translateLabel(filterType.displayName)}: ${this.translateLabel(simpleFirstItem.display)}`;
      }
    }

    // if there are more than 1 selected values, show the additional value
    if (filter.selected.length > 1) {
      displayValue += ` +${filter.selected.length - 1} ${this.translateLabel('more')}`;
    }

    return displayValue;
  }

}
