import { KpiSheetColumn } from '../../../../_shared/components/kpi-sheet/kpi-sheet-models';
import {
    ISocialMediaOverviewConfig,
    ISocialMediaOverviewBillboardConfig,
    IDualAxisLineGraphConfig,
    ICardHeaderConfig,
    IDefaultTableColumnConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class SocialMediaOverviewConfig implements ISocialMediaOverviewConfig {
    public reportTitle = 'Social Media';
    public useDefaultComparisonColumns = true;
    public billboards: ISocialMediaOverviewBillboardConfig[] = [
        {
            title: 'Total Fans/Followers',
            subtitle: '',
            iconClass: 'fa-user fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'totalFansFollowers',
            metricMOMPropertyName: 'totalFansFollowersMOM',
            metricYOYPropertyName: 'totalFansFollowersYOY',
            metricPreviousMonthPropertyName: 'totalFansFollowersPreviousMonth',
            metricPreviousMTDPropertyName: 'totalFansFollowersPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: 'totalFansFollowersPreviousYear',
            trendArrowDrivingPropertyName: 'totalFansFollowersMOM',
            showTrendArrow: true,
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: constants.helpTextKeys.socialMediaOverviewTotalFansFollowersBillboardHelp,
            helpTextTitle: 'Total Fans/Followers'
        },
        {
            title: 'Total posts',
            subtitle: '',
            iconClass: 'fa-comments fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'totalPosts',
            metricMOMPropertyName: 'totalPostsMOM',
            metricYOYPropertyName: 'totalPostsYOY',
            metricPreviousMonthPropertyName: 'totalPostsPreviousMonth',
            metricPreviousMTDPropertyName: 'totalPostsPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: 'totalPostsPreviousYear',
            trendArrowDrivingPropertyName: 'totalPostsMOM',
            showTrendArrow: true,
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: constants.helpTextKeys.socialMediaOverviewTotalPostsBillboardHelp,
            helpTextTitle: 'Total Posts'
        },
        {
            title: 'Total Reach',
            subtitle: '',
            iconClass: 'fa-comments fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'totalReach',
            metricMOMPropertyName: 'totalReachMOM',
            metricYOYPropertyName: 'totalReachYOY',
            metricPreviousMonthPropertyName: 'totalReachPreviousMonth',
            metricPreviousMTDPropertyName: 'totalReachPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: 'totalReachPreviousYear',
            trendArrowDrivingPropertyName: 'totalReachMOM',
            showTrendArrow: true,
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: constants.helpTextKeys.socialMediaOverviewTotalReachBillboardHelp,
            helpTextTitle: 'Total Reach'
        },
        {
            title: 'Total Engagement',
            subtitle: '',
            iconClass: 'fa-comments fa-primary',
            reverseMetric: false,
            metricCurrentPropertyName: 'totalEngagement',
            metricMOMPropertyName: 'totalEngagementMOM',
            metricYOYPropertyName: 'totalEngagementYOY',
            metricPreviousMonthPropertyName: 'totalEngagementPreviousMonth',
            metricPreviousMTDPropertyName: 'totalEngagementPreviousMTD',
            metricPreviousMTDDisplayOnCurrentMonth: true,
            metricPreviousMTDDisplayHistorical: false,
            metricPreviousMTDLabel: 'Prev. MTD',
            metricPreviousMonthDisplayOnCurrentMonth: true,
            metricPreviousMonthDisplayHistorical: true,
            metricPreviousMonthLabel: 'Prev. Month',
            metricPreviousYearPropertyName: 'totalEngagementPreviousYear',
            trendArrowDrivingPropertyName: 'totalEngagementMOM',
            showTrendArrow: true,
            metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
            footerRowCount: 1,
            footerTwoLeftLabel: 'MOM',
            footerTwoRightLabel: 'YOY',
            footerTwoMetricFormatKey: constants.formatKeys.percentageOneDecimal,
            helpTextKey: constants.helpTextKeys.socialMediaOverviewTotalEngagementBillboardHelp,
            helpTextTitle: 'Total Engagement',
        }
    ];

    public kpiSheetOptions = {
        overview: [{
            name: 'providerId',
            label: 'Provider',
            isHeader: true
        }, {
            name: 'totalFansFollowers',
            label: 'Total Fans/Followers',
            format: constants.formatKeys.localeString
        }, {
            name: 'totalPosts',
            label: 'Total Posts',
            format: constants.formatKeys.localeString
        }, {
            name: 'totalReach',
            label: 'Total Reach',
            format: constants.formatKeys.localeString
        },{
            name: 'totalEngagement',
            label: 'Total Engagement',
            format: constants.formatKeys.localeString
        }]
    };

    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.socialMediaOverviewSummaryHelp,
        helpTextTitle: 'Social Media Summary',
        exportName: 'Social Media - Summary Data',
        metricDisplayModes: ['MOM', 'YOY']
    };

    public trendGraphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.socialMediaMetrics.totalFansFollowers.metricId,
                displayName: Enums.socialMediaMetrics.totalFansFollowers.name,
                propertyName: Enums.socialMediaMetrics.totalFansFollowers.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.socialMediaMetrics.totalPosts.metricId,
                displayName: Enums.socialMediaMetrics.totalPosts.name,
                propertyName: Enums.socialMediaMetrics.totalPosts.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.socialMediaMetrics.totalReach.metricId,
                displayName: Enums.socialMediaMetrics.totalReach.name,
                propertyName: Enums.socialMediaMetrics.totalReach.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.socialMediaMetrics.totalEngagement.metricId,
                displayName: Enums.socialMediaMetrics.totalEngagement.name,
                propertyName: Enums.socialMediaMetrics.totalEngagement.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
        ],
        defaultMetricOneId: Enums.socialMediaMetrics.totalFansFollowers.metricId,
        defaultMetricTwoId: Enums.socialMediaMetrics.totalPosts.metricId,
        metricOneColorHexCode: '#05141F',
        metricTwoColorHexCode: '#9EA1A2'
    };
    public dealerTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: '',
            columnDef: 'entity',
            hasComparisonMetrics: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: true
        },
        {
            show: false,
            print: true,
            header: 'Dealer Name',
            columnDef: 'displayName',
            hasComparisonMetrics: false,
            metricFormatKey: constants.formatKeys.entityDisplayName
        },
        {
            show: true,
            header: 'Total Fans/Followers',
            columnDef: 'totalFansFollowers', hasComparisonMetrics: true, metricFormatKey: constants.formatKeys.localeString },
        {
            show: true,
            header: 'Total Posts',
            columnDef: 'totalPosts', hasComparisonMetrics: true, metricFormatKey: constants.formatKeys.localeString },
        {
            show: true,
            header: 'Total Reach',
            columnDef: 'totalReach', hasComparisonMetrics: true, metricFormatKey: constants.formatKeys.localeString },
        {
            show: true,
            header: 'Total Engagement',
            columnDef: 'totalEngagement', hasComparisonMetrics: true, metricFormatKey: constants.formatKeys.localeString },
    ];
}
