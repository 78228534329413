import {
    IDigAdScorecardConfig, IDigAdScorecardCategoryConfig } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdScorecardConfig implements IDigAdScorecardConfig {
    public reportTitle = 'Digital Advertising Scorecard';
    public scorecards: IDigAdScorecardCategoryConfig[] = [
        {
            category: 'Awareness',
            billboards: [
                {
                    title: 'Impressions',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'impressions',
                    metricMOMPropertyName: 'impressionsMOM',
                    metricYOYPropertyName: 'impressionsYOY',
                    metricPreviousMonthPropertyName: 'impressionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'impressionsPreviousMTD',
                    metricPreviousYearPropertyName: 'impressionsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'impressionsMOM',
                    helpTextKey: 'DigAdScorecardImpressionsHelp',
                    helpTextTitle: 'Impressions',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Click Through Rate',
                    subtitle: '',
                    iconClass: 'fa-mouse-pointer fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickThroughRate',
                    metricMOMPropertyName: 'clickThroughRateMOM',
                    metricYOYPropertyName: 'clickThroughRateYOY',
                    metricPreviousMonthPropertyName: 'clickThroughRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'clickThroughRatePreviousMTD',
                    metricPreviousYearPropertyName: 'clickThroughRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clickThroughRateMOM',
                    helpTextKey: 'DigAdScorecardClickThroughRateHelp',
                    helpTextTitle: 'Click Through Rate',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Cost per Thousand (CPM)',
                    subtitle: '',
                    iconClass: 'fa-dollar fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'actualCostPerThousandImpressions',
                    metricMOMPropertyName: 'actualCostPerThousandImpressionsMOM',
                    metricYOYPropertyName: 'actualCostPerThousandImpressionsYOY',
                    metricPreviousMonthPropertyName: 'actualCostPerThousandImpressionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'actualCostPerThousandImpressionsPreviousMTD',
                    metricPreviousYearPropertyName: 'actualCostPerThousandImpressionsPreviousYear',
                    metricFormatKey: constants.formatKeys.currency,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'actualCostPerThousandImpressionsMOM',
                    helpTextKey: 'DigAdScorecardCostPerThousandImpressionsHelp',
                    helpTextTitle: 'Cost per Thousand (CPM)',
                    scoreCardCategory: 'Awareness'
                }
            ],
        },
        {
            category: 'Traffic',
            billboards: [
                {
                    title: 'Visits',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'providerTotalVisits',
                    metricMOMPropertyName: 'providerTotalVisitsMOM',
                    metricYOYPropertyName: 'providerTotalVisitsYOY',
                    metricPreviousMonthPropertyName: 'providerTotalVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'providerTotalVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'providerTotalVisitsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'providerTotalVisitsMOM',
                    helpTextKey: 'DigAdScorecardVisitsHelp',
                    helpTextTitle: 'Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Unique Visits',
                    subtitle: '',
                    iconClass: 'fa-user fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'providerUniqueVisits',
                    metricMOMPropertyName: 'providerUniqueVisitsMOM',
                    metricYOYPropertyName: 'providerUniqueVisitsYOY',
                    metricPreviousMonthPropertyName: 'providerUniqueVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'providerUniqueVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'providerUniqueVisitsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'providerUniqueVisitsMOM',
                    helpTextKey: 'DigAdScorecardUniqueVisitsHelp',
                    helpTextTitle: 'Unique Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Cost per Click',
                    subtitle: '',
                    iconClass: 'fa-dollar fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'actualCostPerClick',
                    metricMOMPropertyName: 'actualCostPerClickMOM',
                    metricYOYPropertyName: 'actualCostPerClickYOY',
                    metricPreviousMonthPropertyName: 'actualCostPerClickPreviousMonth',
                    metricPreviousMTDPropertyName: 'actualCostPerClickPreviousMTD',
                    metricPreviousYearPropertyName: 'actualCostPerClickPreviousYear',
                    metricFormatKey: constants.formatKeys.currency,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'actualCostPerClickMOM',
                    helpTextKey: 'DigAdScorecardCostPerClickHelp',
                    helpTextTitle: 'Cost Per Click',
                    scoreCardCategory: 'Traffic'
                }
            ]
        },
        {
            category: 'Engagement',
            billboards: [
                {
                    title: 'Bounce Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'taggingBounceRate',
                    metricMOMPropertyName: 'taggingBounceRateMOM',
                    metricYOYPropertyName: 'taggingBounceRateYOY',
                    metricPreviousMonthPropertyName: 'taggingBounceRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'taggingBounceRatePreviousMTD',
                    metricPreviousYearPropertyName: 'taggingBounceRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'taggingBounceRateMOM',
                    helpTextKey: 'DigAdScorecardBounceRatesHelp',
                    helpTextTitle: 'Bounce Rate',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'Engagement Rate',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'engagementRate',
                    metricMOMPropertyName: 'engagementRateMOM',
                    metricYOYPropertyName: 'engagementRateYOY',
                    metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
                    metricPreviousYearPropertyName: 'engagementRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'engagementRateMOM',
                    helpTextKey: 'DigAdScorecardEngagementRateHelp',
                    helpTextTitle: 'Engagement Rate',
                    scoreCardCategory: 'Engagement'
                },
            ]
        },
        {
            category: 'Actions',
            billboards: [
                {
                    title: 'Form Submissions',
                    subtitle: '',
                    iconClass: 'fa-file-text fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'providerFormSubmissions',
                    metricMOMPropertyName: 'providerFormSubmissionsMOM',
                    metricYOYPropertyName: 'providerFormSubmissionsYOY',
                    metricPreviousMonthPropertyName: 'providerFormSubmissionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'providerFormSubmissionsPreviousMTD',
                    metricPreviousYearPropertyName: 'providerFormSubmissionsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'providerFormSubmissionsMOM',
                    helpTextKey: 'DigAdScorecardFormSubmissionsHelp',
                    helpTextTitle: 'Form Submissions',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Phone Calls',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'phoneCalls',
                    metricMOMPropertyName: 'phoneCallsMOM',
                    metricYOYPropertyName: 'phoneCallsYOY',
                    metricPreviousMonthPropertyName: 'phoneCallsPreviousMonth',
                    metricPreviousMTDPropertyName: 'phoneCallsPreviousMTD',
                    metricPreviousYearPropertyName: 'phoneCallsPreviousYear',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'phoneCallsMOM',
                    helpTextKey: 'DigAdScorecardPhoneCallsHelp',
                    helpTextTitle: 'Phone Calls',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Action Rate',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'actionRate',
                    metricMOMPropertyName: 'actionRateMOM',
                    metricYOYPropertyName: 'actionRateYOY',
                    metricPreviousMonthPropertyName: 'actionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'actionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'actionRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.percentageNoDecimals,
                    trendArrowDrivingPropertyName: 'actionRateMOM',
                    helpTextKey: 'DigAdScorecardActionRateHelp',
                    helpTextTitle: 'Action Rate',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Conversion Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'conversionRate',
                    metricMOMPropertyName: 'conversionRateMOM',
                    metricYOYPropertyName: 'conversionRateYOY',
                    metricPreviousMonthPropertyName: 'conversionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'conversionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'conversionRatePreviousYear',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'conversionRateMOM',
                    helpTextKey: 'DigAdScorecardConversionRateHelp',
                    helpTextTitle: 'Conversion Rate',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Cost per Acquisition',
                    subtitle: '',
                    iconClass: 'fa-dollar fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'actualCostPerAcquisition',
                    metricMOMPropertyName: 'actualCostPerAcquisitionMOM',
                    metricYOYPropertyName: 'actualCostPerAcquisitionYOY',
                    metricPreviousMonthPropertyName: 'actualCostPerAcquisitionPreviousMonth',
                    metricPreviousMTDPropertyName: 'actualCostPerAcquisitionPreviousMTD',
                    metricPreviousYearPropertyName: 'actualCostPerAcquisitionPreviousYear',
                    metricFormatKey: constants.formatKeys.currency,
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'actualCostPerAcquisitionMOM',
                    helpTextKey: 'DigAdScorecardCostPerAcquisitionHelp',
                    helpTextTitle: 'Cost Per Acquisition',
                    scoreCardCategory: 'Traffic'
                }
            ]
        }
    ];
}
